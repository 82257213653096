<template>
    <div class="login-container">
        <el-row class="loginBox">
            <div class="loginBox-left">
                <img class="log2" src="https://static-cos.xbov.cn/pc_web_img/collectionSystem/logo2.png" />
            </div>
            <div class="loginBox-right">
                <el-row class="logoBox">
                    <img class="logo" src="https://resources.xbov.cn/img/collectionSystem/logo%403x.png" />
                    <span class="logoTitle">鸿星智能广告采集平台</span>
                </el-row>
                <el-form ref="form" class="login-form" label-position="left" :model="loginForm" :rules="rules">
                    <el-form-item inline-message prop="loginName">
                        <el-input v-model.trim="loginForm.loginName" v-focus placeholder="请输入手机号" tabindex="1" type="text">
                            <template #prefix>
                                <i class="el-icon-mobile-phone" />
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="phoneCode">
                        <el-input style="width: 70%" v-model.trim="loginForm.phoneCode" placeholder="请输入验证码" tabindex="3" type="text" @keyup.enter.native="handleLogin">
                            <template #prefix>
                                <i class="el-icon-message" />
                            </template>
                        </el-input>
                        <el-button :loading="getCodeLoading" style="width: 30%" class="code" type="primary" @click="getCode" :disabled="sendSmsText != '发送验证码'"> {{ sendSmsText }} </el-button>
                    </el-form-item>
                    <el-row class="submitBtnBox">
                        <el-button style="width: 70%" round class="login-btn" :loading="loading" type="primary" @click="handleLogin"> 登&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;录 </el-button>
                    </el-row>
                </el-form>
            </div>
        </el-row>
        <el-row class="footer"
            >© Copyright 2015 - {{ moment().format("YYYY") }} 长沙鸿星智能科技有限公司 版权所有 | <a href="https://beian.miit.gov.cn" target="_blank" style="color: white">湘ICP备2020023992号-2号</a> |
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43019002001389" target="_blank" style="color: white">湘公网安备 43019002001389号</a></el-row
        >
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { isPhone, isSmsCode } from "@/utils/validate";
import { smsSendCode } from "@/api/common";
import moment from "moment";

export default {
    name: "Login",
    directives: {
        focus: {
            inserted(el) {
                el.querySelector("input").focus();
            },
        },
    },
    beforeRouteLeave(to, from, next) {
        clearInterval(this.timer);
        next();
    },
    data() {
        const validateTelPhone = (rule, value, callback) => {
            if ("" == value) callback(new Error("手机号码不能为空"));
            else if (!isPhone(value)) callback(new Error("手机号格式不正确"));
            else callback();
        };
        const verificationCode = (rule, value, callback) => {
            if ("" == value) callback(new Error("验证码不能为空"));
            if (!isSmsCode(value)) callback(new Error("验证码不正确"));
            else callback();
        };
        return {
            getCodeLoading: false,
            moment,
            sendSmsText: "发送验证码",
            title: "户外广告采集管理平台",
            loginForm: {
                loginName: "",
                phoneCode: "",
                clientType: 0,
            },
            rules: {
                loginName: [
                    {
                        required: true,
                        trigger: "blur",
                        validator: validateTelPhone,
                    },
                ],
                phoneCode: [
                    {
                        required: true,
                        trigger: "blur",
                        validator: verificationCode,
                    },
                ],
            },
            loading: false,
            passwordType: "password",
            redirect: undefined,
            timer: null,
            previewText: "",
        };
    },
    computed: {},
    watch: {
        $route: {
            handler(route) {
                this.redirect = (route.query && route.query.redirect) || "/";
            },
        },
    },
    mounted() {},
    methods: {
        getCode() {
            const _this = this;
            _this.getCodeLoading = true;
            this.$refs.form.validateField("loginName", (valid) => {
                if (valid == "") {
                    let params = {
                        phone: _this.loginForm.loginName,
                    };
                    smsSendCode(params)
                        .then((res) => {
                            _this.getCodeLoading = false;
                            let num = 60;
                            _this.sendSmsText = num + "S";
                            _this.timer = setInterval(() => {
                                num -= 1;
                                _this.sendSmsText = num + "S";
                                if (num == 0) {
                                    clearInterval(_this.timer);
                                    _this.sendSmsText = "发送验证码";
                                }
                            }, 1000);
                        })
                        .catch((err) => {
                            _this.getCodeLoading = false;
                        });
                }
            });
        },
        ...mapActions({
            login: "user/login",
        }),
        handleRoute() {
            return this.redirect === "/404" || this.redirect === "/403" ? "/" : this.redirect;
        },
        handleLogin() {
            const _this = this;
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    _this.loading = true;
                    _this.$store
                        .dispatch("user/login", _this.loginForm)
                        .then(() => {
                            if (_this.timer) {
                                clearInterval(_this.timer);
                            }
                            _this.sendSmsText = "发送验证码";
                            _this.$router.replace({ path: "/" });
                            _this.loading = false;
                        })
                        .catch(() => {
                            _this.loading = false;
                        });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px 0;
    text-align: center;
    color: #ffffff;
}
.login-container {
    height: 100vh;
    background: url("https://static-cos.xbov.cn/pc_web_img/collectionSystem/login_bg.png") center center fixed no-repeat;
    background-size: cover;
    position: relative;
    .loginBox {
        width: 1464px;
        height: 550px;
        background: #ffffff;
        border-radius: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;
        display: flex;
        &-left {
            width: 900px;
            height: 100%;
            background: url("https://static-cos.xbov.cn/pc_web_img/collectionSystem/loginForm_bg.png") no-repeat;
            background-size: cover;
            position: relative;
            .log2 {
                width: 308px;
                height: 364px;
                position: absolute;
                top: 90px;
                right: 158px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &-right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 564px;
            height: 100%;
            padding: 82px 75px;
            /deep/ .el-form-item {
                margin-bottom: 40px;
            }
            .login-form {
                .submitBtnBox {
                    text-align: center;
                    .login-btn {
                        font-size: 18px;
                    }
                }
            }
            .logoBox {
                display: flex;
                align-items: center;
                margin-bottom: 48px;
                .logoTitle {
                    font-size: 32px;
                }
                .logo {
                    // width: 60px;
                    height: 50px;
                    margin-right: 16px;
                }
            }
        }
    }
}

// .login-form {
//     position: relative;
//     max-width: 100%;
//     padding: 4.5vh;
//     margin: calc((100vh - 475px) / 2) 5vw 5vw;
//     overflow: hidden;
//     background: url("~@/assets/login_images/login_form.png");
//     background-size: 100% 100%;

//     .title {
//         font-size: 54px;
//         font-weight: 500;
//         color: #fff;
//     }

//     .title-tips {
//         margin-top: 29px;
//         font-size: 26px;
//         font-weight: 400;
//         color: #fff;
//     }

//     .login-btn {
//         display: inherit;
//         width: 100%;
//         height: 50px;
//         margin-top: 5px;
//         border: 0;

//         &:hover {
//             opacity: 0.9;
//         }

//         .forget-passwordword {
//             width: 100%;
//             margin-top: 40px;
//             text-align: left;

//             .forget-password {
//                 width: 129px;
//                 height: 19px;
//                 font-size: 20px;
//                 font-weight: 400;
//                 color: rgba(92, 102, 240, 1);
//             }
//         }
//     }

//     .tips {
//         margin-bottom: 10px;
//         font-size: 14px;
//         color: #fff;

//         span {
//             &:first-of-type {
//                 margin-right: 16px;
//             }
//         }
//     }

//     .title-container {
//         position: relative;

//         .title {
//             margin: 0 auto 40px auto;
//             font-size: 34px;
//             font-weight: bold;
//             color: #1890ff;
//             text-align: center;
//         }
//     }

//     i {
//         position: absolute;
//         top: 15px;
//         left: 5px;
//         z-index: 1999;
//         font-size: 16px;
//         color: #d7dee3;
//         cursor: pointer;
//         user-select: none;
//     }

//     .show-password {
//         position: absolute;
//         right: 25px;
//         left: -35px;
//         font-size: 16px;
//         color: #d7dee3;
//         cursor: pointer;
//         user-select: none;
//     }

//     ::v-deep {
//         .el-form-item {
//             padding-right: 0;
//             margin: 20px 0;
//             color: #454545;
//             background: transparent;
//             border: 1px solid transparent;
//             border-radius: 2px;

//             &__content {
//                 min-height: 32px;
//                 line-height: 32px;
//             }

//             &__error {
//                 position: absolute;
//                 top: 100%;
//                 left: 18px;
//                 font-size: 12px;
//                 line-height: 18px;
//                 color: #ff4d4f;
//             }
//         }

//         .el-input {
//             box-sizing: border-box;

//             input {
//                 height: 48px;
//                 padding-left: 35px;
//                 font-size: 14px;
//                 line-height: 58px;
//                 background: #f6f4fc;
//                 border: 0;
//             }
//         }

//         .code {
//             position: absolute;
//             top: 4px;
//             right: 4px;
//             cursor: pointer;
//             border-radius: 2.5px;
//         }
//     }
// }
</style>
